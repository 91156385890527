exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-capabilities-js": () => import("./../../../src/pages/capabilities.js" /* webpackChunkName: "component---src-pages-capabilities-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-application-js": () => import("./../../../src/pages/job-application.js" /* webpackChunkName: "component---src-pages-job-application-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-perspective-options-js": () => import("./../../../src/pages/perspective-options.js" /* webpackChunkName: "component---src-pages-perspective-options-js" */),
  "component---src-pages-perspectives-christine-schwall-pecci-js": () => import("./../../../src/pages/perspectives/christine-schwall-pecci.js" /* webpackChunkName: "component---src-pages-perspectives-christine-schwall-pecci-js" */),
  "component---src-pages-perspectives-ella-maughan-js": () => import("./../../../src/pages/perspectives/ella-maughan.js" /* webpackChunkName: "component---src-pages-perspectives-ella-maughan-js" */),
  "component---src-pages-perspectives-eric-brandel-js": () => import("./../../../src/pages/perspectives/eric-brandel.js" /* webpackChunkName: "component---src-pages-perspectives-eric-brandel-js" */),
  "component---src-pages-perspectives-eric-kallin-js": () => import("./../../../src/pages/perspectives/eric-kallin.js" /* webpackChunkName: "component---src-pages-perspectives-eric-kallin-js" */),
  "component---src-pages-perspectives-erika-lesser-lori-oneill-js": () => import("./../../../src/pages/perspectives/erika-lesser-lori-oneill.js" /* webpackChunkName: "component---src-pages-perspectives-erika-lesser-lori-oneill-js" */),
  "component---src-pages-perspectives-js": () => import("./../../../src/pages/perspectives.js" /* webpackChunkName: "component---src-pages-perspectives-js" */),
  "component---src-pages-perspectives-kat-js": () => import("./../../../src/pages/perspectives/kat.js" /* webpackChunkName: "component---src-pages-perspectives-kat-js" */),
  "component---src-pages-perspectives-lila-shah-wright-js": () => import("./../../../src/pages/perspectives/lila-shah-wright.js" /* webpackChunkName: "component---src-pages-perspectives-lila-shah-wright-js" */),
  "component---src-pages-press-releases-art-chavez-js": () => import("./../../../src/pages/press-releases/art-chavez.js" /* webpackChunkName: "component---src-pages-press-releases-art-chavez-js" */),
  "component---src-pages-press-releases-bgb-group-acquires-healthcare-consultancy-kx-advisors-js": () => import("./../../../src/pages/press-releases/bgb-group-acquires-healthcare-consultancy-kx-advisors.js" /* webpackChunkName: "component---src-pages-press-releases-bgb-group-acquires-healthcare-consultancy-kx-advisors-js" */),
  "component---src-pages-press-releases-bgb-group-announces-partnership-with-scrum-50-js": () => import("./../../../src/pages/press-releases/bgb-group-announces-partnership-with-scrum50.js" /* webpackChunkName: "component---src-pages-press-releases-bgb-group-announces-partnership-with-scrum-50-js" */),
  "component---src-pages-press-releases-fred-bennett-js": () => import("./../../../src/pages/press-releases/fred-bennett.js" /* webpackChunkName: "component---src-pages-press-releases-fred-bennett-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-press-releases-new-leadership-team-js": () => import("./../../../src/pages/press-releases/new-leadership-team.js" /* webpackChunkName: "component---src-pages-press-releases-new-leadership-team-js" */),
  "component---src-pages-press-releases-teresa-js": () => import("./../../../src/pages/press-releases/teresa.js" /* webpackChunkName: "component---src-pages-press-releases-teresa-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-vital-signs-js": () => import("./../../../src/pages/vital-signs.js" /* webpackChunkName: "component---src-pages-vital-signs-js" */),
  "component---src-pages-vital-signs-social-listening-js": () => import("./../../../src/pages/vital-signs/social-listening.js" /* webpackChunkName: "component---src-pages-vital-signs-social-listening-js" */),
  "component---src-pages-vital-signs-teaser-js": () => import("./../../../src/pages/vital-signs/teaser.js" /* webpackChunkName: "component---src-pages-vital-signs-teaser-js" */)
}

